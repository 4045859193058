import React from 'react';
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import SectionTitle from '@components/atoms/SectionTitle';
import HeroJob from '@components/molecules/heros/HeroJob';

const language = 'fr';
const pageClass = 'page-job';

const footerRelatedLinks = [
	{ title: 'L’Équipe', url: '/fr/equipe/' },
	{ title: 'Culture', url: '/fr/culture/' },
];

const JobDetailPage = (props) => {
	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/en/jobs/digital-manager/', lang: 'en' }]}
			title="Rejoins superhuit en tant que Responsable Digital à Lausanne"
			seo={{
				description: `Développer et gérer l'offre digitale de l'agence.`,
			}}
		>
			<HeroJob
				breadcrumb={{ title: 'Postes ouverts', link: '/fr/rejoindre/' }}
				title="Responsable Digital"
				location="Lausanne, Suisse"
				anchors={[
					{
						title: 'Profil recherché',
						href: '#you-are',
					},
					{
						title: 'Nous sommes',
						href: '#we-are',
					},
				]}
			/>
			<section
				className="grid text-content text-center"
				aria-label="Introduction"
			>
				<h2 data-animation-page className="col-big">
					Développer et gérer l'offre digitale de l'agence.
				</h2>
			</section>

			<section
				id="you-are"
				className="grid grid-content"
				aria-label="Compétences &amp; Personnalité"
			>
				<div className="box text-content" data-animation-page>
					<p className="uptitle">Compétences &amp; Personnalité</p>
					<div className="list-two-columns">
						<div className="text-content">
							<h3>Rôle et missions</h3>
							<p>En tant que Responsable Digital, vous serez chargé(e) de développer et de gérer l’offre digitale de l’agence. Votre mission principale consistera à vendre et coordonner des projets web ambitieux, en étroite collaboration avec nos clients et une équipe talentueuse de développeur-se-s, designers, gestionnaires de projet ainsi que les associés.</p>
							<ul className="list-default">
								<li>Conseiller les clients et creuser leurs enjeux business pour proposer des solutions adaptées</li>
								<li>Créer des offres, participer aux stratégies de design et aux pitchs</li>
								<li>Être responsable du scope, du calendrier et des échéances de différents projets</li>
								<li>Assurer un suivi et pilotage de grande qualité auprès de l'équipe interne comme des clients</li>
							</ul>
						</div>
						<div className="text-content">
							<h3>Profil recherché</h3>
							<ul className="list-default">
								<li>Une expérience confirmée (3+ ans) en stratégie/conception/vente digitale, en agence créative ou similaire</li>
								<li>Un sens aigu de l'écoute et de l'empathie pour comprendre et accompagner nos clients</li>
								<li>Une maîtrise des processus digitaux (UX, UI, développement) avec une grande attention aux détails</li>
								<li>Animé par la performance, tant commerciale que dans l'atteinte des objectifs de nos clients</li>
								<li>Une affinité avec les défis liés à l'innovation (IA, phygital, etc.)</li>
								<li>Une connaissance approfondie du marché suisse</li>
								<li>Compétences démontrées en gestion de projet et à l'aise avec divers outils de gestion</li>
								<li>Excellentes aptitudes en communication écrite et orale, en français et anglais (l'allemand est un plus)</li>
								<li>Bonne résistance au stress et excellent sens de l'organisation</li>
								<li>Une expérience ou intérêt pour le service design, l'UX ou le marketing digital est un atout majeur</li>
								<li>Toute passion pour les domaines liés aux digital, brand, business est un plus</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section id="we-are" aria-label="Superhuit">
				<SectionTitle title="Travaillons ensemble" />

				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">Superhuit</p>
						<h2>Travaillons ensemble</h2>
						<div className="list-two-columns">
							<div className="text-content">
								<h4>Nous sommes</h4>
								<p>
									superhuit est une agence créative née à
									Lausanne en 2013, spécialisée dans le
									branding, design et digital. Superhuit
									incarne l'alliance de la stratégie, de la
									créativité et de la technologie pour
									concevoir et faire rayonner nos clients.
								</p>
								<p>
									Nous croyons que chaque marque ambitieuse
									peut devenir un levier de changement
									positif, alliant sens, esthétique et
									performance.
								</p>
								<p>
									La collaboration est au coeur de nos
									processus centrés sur l'utilisateur et nous
									accompagnons nos clients dans l'ensemble de
									leur transformation digitale.
								</p>
								<p>
									Nous sommes constamment à la recherche de
									projets innovants et variés que notre équipe
									de passionné·e·s aime gérer.
								</p>
								<p>
									Chez nous, chacun·e contribue au
									développement de l'agence. Et parce que
									travailler avec une équipe d'amis n'a pas de
									prix, nous prenons régulièrement du bon
									temps ensemble !
								</p>
							</div>
							<div className="text-content">
								<h4>Nous offrons</h4>
								<ul className="list-default">
									<li>
										Une collaboration étroite et stratégique
										avec l'ensemble de l'équipe pour
										dessiner le futur de l'agence.
									</li>
									<li>
										En tant qu'entreprise socialement
										responsable, l'un de nos objectifs est
										de fournir un environnement qui
										contribue au bien-être et à la
										croissance professionnelle de chaque
										membre de l'équipe.
									</li>
									<li>
										Nous croyons au collectif et partageons
										les bénéfices au sein de l'équipe.
									</li>
									<li>
										La confiance est une priorité et
										chacun·e gère son temps comme il/elle le
										souhaite (2 jours de remote / semaine),
										tant que nous atteignons nos objectifs
										en tant qu'équipe.
									</li>
									<li>
										La flexibilité, la collaboration et
										l'autogestion sont nos valeurs clés et
										nous encourageons les feedbacks
										réguliers, car ils représentent une
										opportunité pour nous améliorer.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section aria-label="Postuler" className="grid" id="apply">
				<div
					className="text-center box box--no-border text-content"
					data-animation-page
				>
					<h2>Intéressé·e?</h2>
					<p>
						<span
							style={{ display: 'inline-block', maxWidth: 500 }}
						>
							Écrivez-nous à{' '}
							<Link
								href="mailto:job@superhuit.ch"
								style={{ whiteSpace: 'pre' }}
							>
								job@superhuit.ch
							</Link>{' '}
							et montrez-nous ce qui fait votre fierté.
						</span>
					</p>
				</div>
			</section>
		</Layout>
	);
};

export default JobDetailPage;
