import React from 'react';
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import SectionTitle from '@components/atoms/SectionTitle';
import HeroJob from '@components/molecules/heros/HeroJob';

const language = 'en';
const pageClass = 'page-job';

const footerRelatedLinks = [
	{ title: 'Team', url: '/en/team/' },
	{ title: 'Culture', url: '/en/culture/' },
];

const JobDetailPage = (props) => {
	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[
				{ uri: '/fr/rejoindre/responsable-digital/', lang: 'fr' },
			]}
			title="Join superhuit as Digital Manager in Lausanne"
			seo={{
				description: `Develop and manage the agency's digital offering.`,
			}}
		>
			<HeroJob
				breadcrumb={{ title: 'Open Positions', link: '/en/jobs/' }}
				title="Digital Manager"
				location="Lausanne, Switzerland"
				anchors={[
					{
						title: 'Profile',
						href: '#you-are',
					},
					{
						title: 'We are',
						href: '#we-are',
					},
				]}
			/>
			<section
				className="grid text-content text-center"
				aria-label="Introduction"
			>
				<h2 data-animation-page className="col-big">
					Develop and manage the agency's digital offering.
				</h2>
			</section>

			<section
				id="you-are"
				className="grid grid-content"
				aria-label="Skills &amp; Personality"
			>
				<div className="box text-content" data-animation-page>
					<p className="uptitle">Skills &amp; Personality</p>
					<div className="list-two-columns">
						<div className="text-content">
							<h3>Role and responsibilities</h3>
							<p>As Digital Manager, you will be responsible for developing and managing the agency's digital offering. Your main mission will be to sell and coordinate ambitious web projects, working closely with our clients and a talented team of developers, designers, project managers and partners.</p>
							<ul className="list-default">
								<li>Advise clients and explore their business challenges to propose tailored solutions</li>
								<li>Create proposals, participate in design strategies and pitches</li>
								<li>Be responsible for the scope, timeline and deadlines of various projects</li>
								<li>Ensure high-quality monitoring and management with both internal team and clients</li>
							</ul>
						</div>
						<div className="text-content">
							<h3>Required profile</h3>
							<ul className="list-default">
								<li>Proven experience (3+ years) in digital strategy/design/sales, in a creative agency or similar</li>
								<li>Strong listening and empathy skills to understand and support our clients</li>
								<li>Mastery of digital processes (UX, UI, development) with great attention to detail</li>
								<li>Driven by performance, both commercially and in achieving client objectives</li>
								<li>Affinity with innovation challenges (AI, phygital, etc.)</li>
								<li>In-depth knowledge of the Swiss market</li>
								<li>Demonstrated project management skills and comfort with various management tools</li>
								<li>Excellent written and verbal communication skills in French and English (German is a plus)</li>
								<li>Good stress resistance and excellent organizational skills</li>
								<li>Experience or interest in service design, UX or digital marketing is a major asset</li>
								<li>Any passion for digital, brand, and business domains is a plus</li>
							</ul>
						</div>
					</div>
				</div>
			</section>

			<section id="we-are" aria-label="Superhuit">
				<SectionTitle title="Let's work together" />

				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">Superhuit</p>
						<h2>Let's work together</h2>
						<div className="list-two-columns">
							<div className="text-content">
								<h4>We are</h4>
								<p>
									superhuit is a creative agency born in Lausanne in 2013, specialized in branding, design, and digital. Superhuit embodies the alliance of strategy, creativity, and technology to design and make our clients shine.
								</p>
								<p>
									We believe that every ambitious brand can become a lever for positive change, combining meaning, aesthetics, and performance.
								</p>
								<p>
									Collaboration is at the heart of our user-centered processes, and we support our clients throughout their digital transformation.
								</p>
								<p>
									We are constantly looking for innovative and varied projects that our team of passionate people loves to work on.
								</p>
								<p>
									Everyone contributes to the agency's development. And because working with a team of friends is priceless, we regularly spend quality time together.
								</p>
							</div>
							<div className="text-content">
								<h4>We offer</h4>
								<ul className="list-default">
									<li>
										Close and strategic collaboration with the entire team to shape the future of the agency.
									</li>
									<li>
										As a socially responsible company, one of our goals is to provide an environment that contributes to the well-being and professional growth of each team member.
									</li>
									<li>
										We believe in collective effort and share benefits within the team.
									</li>
									<li>
										Trust is a priority, and everyone manages their time as they wish (2 days remote/week), as long as we achieve our goals as a team.
									</li>
									<li>
										Flexibility, collaboration, and self-management are our key values, and we encourage regular feedback as it represents an opportunity for improvement.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section aria-label="Apply" className="grid" id="apply">
				<div
					className="text-center box box--no-border text-content"
					data-animation-page
				>
					<h2>Interested?</h2>
					<p>
						<span
							style={{ display: 'inline-block', maxWidth: 500 }}
						>
							Write to us at{' '}
							<Link
								href="mailto:job@superhuit.ch"
								style={{ whiteSpace: 'pre' }}
							>
								job@superhuit.ch
							</Link>{' '}
							and show us what makes you proud.
						</span>
					</p>
				</div>
			</section>
		</Layout>
	);
};

export default JobDetailPage; 